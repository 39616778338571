import React from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"

import { Heading, Paragraph, Flex } from "../../elements"

type Props = {
  icon: ImageDataLike
  title: string
  description: string
}

const Merit: React.FC<Props> = ({ icon, title, description }) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    textAlign="center"
    width="100%"
    maxWidth={344}
  >
    <Flex alignItems="center" justifyContent="center" mb="18px">
      <GatsbyImage image={getImage(icon)} alt={title} />
    </Flex>
    <Heading
      as="h3"
      variant="sm"
      mb="24px"
      color="text.inverted.base"
      fontSize={["2rem", null, null, "2.4rem"]}
    >
      {title}
    </Heading>
    <Paragraph variant="sm" color="product.hub.textLight">
      {description}
    </Paragraph>
  </Flex>
)

export default Merit
