import React from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"

import { Heading, Paragraph, Grid, Flex, Box, StyledLink } from "../../elements"

type Props = {
  title: React.ReactNode
  description: string
  link?: string
  icon?: React.ReactNode
  image: ImageDataLike
  reverse?: boolean
}

const Feature: React.FC<Props> = ({
  title,
  description,
  link,
  icon,
  image,
  reverse,
}) => (
  <Grid
    gridTemplateColumns={["1fr", null, null, "1fr 1fr"]}
    alignItems="center"
    gridGap={"40px"}
    justifyItems={["center"]}
  >
    <Flex
      maxWidth="495px"
      order={reverse ? [1, null, null, 2] : 1}
      flexDirection="column"
      justifySelf={
        reverse
          ? ["center", null, null, "end"]
          : ["center", null, null, "start"]
      }
    >
      <Box mb="24px">{icon}</Box>
      <Heading as="h3" variant="md" mb="24px" color="text.inverted.base">
        {title}
      </Heading>
      <Paragraph variant="sm" color="product.hub.textLight">
        {description}
      </Paragraph>
      {link && (
        <StyledLink icon="arrow" color="#7f8c2b" mt="18px" to={link}>
          Learn more
        </StyledLink>
      )}
    </Flex>
    <Flex order={reverse ? [2, null, null, 1] : 2}>
      <GatsbyImage image={getImage(image)} alt={title} />
    </Flex>
  </Grid>
)

export default Feature
