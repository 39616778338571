function astGetCustomField(ast: any, key: string): any {
  if (ast?.properties?.dataCustomField === key) {
    return ast.children.length > 0 ? ast.children[0].value : null
  } else if (ast?.children) {
    let value
    for (let i = 0; i < ast.children.length; i++) {
      value = astGetCustomField(ast.children[i], key)
      if (value != null) {
        break
      }
    }
    return value
  } else {
    return null
  }
}

export default astGetCustomField
