import React, { useState, useRef, useLayoutEffect } from "react"
import styled, { css } from "styled-components"

import { Flex, Button, Heading } from "../../elements"
import {
  CircularGreenCheckList as List,
  CustomBulletListItem as ListItem,
} from "../../elements/Lists"
import ToggleTrack from "../../elements/Forms/ToggleTrack"
import theme from "../../styles/theme"

const PricingTable = () => {
  const [isYearlyBilled, setIsYearlyBilled] = useState(false)
  const pricingTableRef = useRef(null)
  const [pricingTableHeight, setPricingTableHeight] = useState("100%")

  useLayoutEffect(() => {
    if (pricingTableRef.current) {
      setPricingTableHeight(pricingTableRef.current.clientHeight)
    }
  }, [])

  return (
    <Wrapper>
      <Table>
        <Main ref={pricingTableRef}>
          <Head>
            <div></div>
            <Plan
              style={{
                border: `1px solid hsl(214deg 11% 41%)`,
                borderRadius: `12px 0 0 0`,
                borderBottom: `0`,
                borderRight: `0`,
              }}
            >
              <Name>Free</Name>
              <Price>
                <PAmount>$0</PAmount>
              </Price>
              <Tagline>Always free for 2 users per workspace</Tagline>
              <GetStartedButton href="https://hub.traefik.io/dashboard">
                Get Started
              </GetStartedButton>
            </Plan>
            <Plan
              style={{
                border: `1px solid #d5ea48`,
                borderRadius: `12px 12px 0 0`,
                borderBottom: `0`,
                paddingTop: `43px`,
              }}
            >
              <Name>Premium</Name>
              <Price>
                <PAmount>{isYearlyBilled ? `$999` : `$99`}</PAmount>
                <PTime>/{isYearlyBilled ? `year` : `month`}</PTime>
                {isYearlyBilled && <PSaving>Save 16%</PSaving>}
              </Price>
              <BillingToggle alignItems="center">
                <span>Billed monthly</span>
                <ToggleTrack
                  bg={theme.colors.product.hub.accent}
                  color={theme.colors.product.hub.bg}
                  active={isYearlyBilled}
                  onClick={() => setIsYearlyBilled(!isYearlyBilled)}
                  aria-label="Toggle billing duration"
                  style={{
                    margin: "0 8px",
                  }}
                />
                <span>Billed annually</span>
              </BillingToggle>
              <GetStartedGreenButton href="https://hub.traefik.io/dashboard">
                Get Started
              </GetStartedGreenButton>
            </Plan>
            <Plan
              style={{
                border: `1px solid hsl(214deg, 11%, 41%)`,
                borderRadius: `0 12px 0 0`,
                borderBottom: `0`,
                borderLeft: `0`,
              }}
            >
              <Name>Enterprise</Name>
              <Price>
                <PAmount>Custom</PAmount>
              </Price>
              <Tagline>Contact with our sales team</Tagline>
              <GetStartedButton href="https://hub.traefik.io/dashboard">
                Get Started
              </GetStartedButton>
            </Plan>
          </Head>
          <Row style={{ margin: 0 }}>
            <RowInner>
              <RowTitle>Clusters</RowTitle>
              <Cells>
                <TitleCell>Number of clusters</TitleCell>
                <Cell>2</Cell>
                <Cell>5</Cell>
                <Cell>Unlimited</Cell>
              </Cells>
            </RowInner>
          </Row>
          <Row>
            <RowInner>
              <RowTitle>Ingress Controllers</RowTitle>
              <Cells>
                <TitleCell>Ingress Controllers</TitleCell>
                <Cell>
                  <List>
                    <ListItem>Traefik Proxy</ListItem>
                    <ListItem>Nginx</ListItem>
                    <ListItem>HAProxy</ListItem>
                  </List>
                </Cell>
                <Cell>
                  <List>
                    <ListItem>Traefik Proxy</ListItem>
                    <ListItem>Nginx</ListItem>
                    <ListItem>HAProxy</ListItem>
                  </List>
                </Cell>
                <Cell>
                  <List>
                    <ListItem>Traefik Proxy</ListItem>
                    <ListItem>Nginx</ListItem>
                    <ListItem>HAProxy</ListItem>
                  </List>
                </Cell>
              </Cells>
            </RowInner>
          </Row>
          <Row>
            <RowInner>
              <RowTitle>ACCESS CONTROL</RowTitle>
              <Cells>
                <TitleCell>Secured routes per cluster</TitleCell>
                <Cell>3</Cell>
                <Cell>50</Cell>
                <Cell>Unlimited</Cell>
              </Cells>
              <Cells>
                <TitleCell>Authentication</TitleCell>
                <Cell>JWT, BasicAuth</Cell>
                <Cell>JWT, BasicAuth</Cell>
                <Cell>Custom</Cell>
              </Cells>
            </RowInner>
          </Row>
          <Row>
            <RowInner>
              <RowTitle>USERS</RowTitle>
              <Cells>
                <TitleCell>User per workspace</TitleCell>
                <Cell>2</Cell>
                <Cell>20</Cell>
                <Cell>Unlimited</Cell>
              </Cells>
            </RowInner>
          </Row>
          <Row>
            <RowInner>
              <RowTitle>CERTIFICATE MANAGEMENT</RowTitle>
              <Cells>
                <TitleCell>Number of domains per workspace</TitleCell>
                <Cell>10</Cell>
                <Cell>100</Cell>
                <Cell>Unlimited</Cell>
              </Cells>
            </RowInner>
          </Row>
          <Row>
            <RowInner>
              <RowTitle>Global Server Load Balancing</RowTitle>
              <Cells>
                <TitleCell>Bandwidth (per month, per workspace)</TitleCell>
                <Cell>1GB</Cell>
                <Cell>50GB</Cell>
                <Cell>Custom</Cell>
              </Cells>
              <Cells>
                <TitleCell>GSLB features</TitleCell>
                <Cell>
                  <List>
                    <ListItem>Blue-Green Canary</ListItem>
                  </List>
                </Cell>
                <Cell>
                  <List>
                    <ListItem>Blue-Green Canary</ListItem>
                    <ListItem>Active/Active</ListItem>
                    <ListItem>Active/Passive</ListItem>
                    <ListItem>Geo-steering</ListItem>
                  </List>
                </Cell>
                <Cell>
                  <List>
                    <ListItem>Blue-Green Canary</ListItem>
                    <ListItem>Active/Active</ListItem>
                    <ListItem>Active/Passive</ListItem>
                    <ListItem>Geo-steering</ListItem>
                  </List>
                </Cell>
              </Cells>
            </RowInner>
          </Row>
          <Row>
            <RowInner>
              <RowTitle>ALERTING</RowTitle>
              <Cells>
                <TitleCell>
                  Number of triggers allowed per month (for alerting)
                </TitleCell>
                <Cell>5</Cell>
                <Cell>100</Cell>
                <Cell>Unlimited</Cell>
              </Cells>
              <Cells>
                <TitleCell>Resolved alerts history</TitleCell>
                <Cell>Last 10 alerts</Cell>
                <Cell>Last 200 alerts</Cell>
                <Cell>Last 200+ alerts</Cell>
              </Cells>
            </RowInner>
          </Row>
          <Row>
            <RowInner>
              <RowTitle>METRICS</RowTitle>
              <Cells>
                <TitleCell>Data retention (for metrics)</TitleCell>
                <Cell>1 day</Cell>
                <Cell>1 month</Cell>
                <Cell>Custom</Cell>
              </Cells>
            </RowInner>
          </Row>
          <Row>
            <RowInner>
              <RowTitle>SUPPORT</RowTitle>
              <Cells>
                <TitleCell>Support</TitleCell>
                <Cell>
                  <List>
                    <ListItem>Community Forum</ListItem>
                  </List>
                </Cell>
                <Cell>
                  <List>
                    <ListItem>Community Forum</ListItem>
                    <ListItem>Unlimited email support</ListItem>
                  </List>
                </Cell>
                <Cell>
                  <List px="16px">
                    <ListItem>Unlimited email support</ListItem>
                    <ListItem>Direct ticket using Zendesk UI</ListItem>
                    <ListItem>Direct access to the support team</ListItem>
                    <ListItem>2 hours SLA</ListItem>
                    <ListItem>Support 24/7</ListItem>
                  </List>
                </Cell>
              </Cells>
            </RowInner>
          </Row>
        </Main>
        <Gridlines height={pricingTableHeight}>
          <div></div>
          <div
            style={{
              overflow: "hidden",
            }}
          >
            <Gridline />
            <Gridline premium blur />
          </div>
          <div>
            <Gridline premium />
            <Gridline premium />
          </div>
          <div
            style={{
              overflow: "hidden",
            }}
          >
            <Gridline premium blur />
            <Gridline />
          </div>
        </Gridlines>
      </Table>
    </Wrapper>
  )
}

export default PricingTable

const Wrapper = styled.div`
  @media (max-width: 970px) {
    overflow-x: scroll;
  }

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const Table = styled.div`
  position: relative;
  min-width: 900px;
`

const Main = styled.div``

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`

/* Table head */
const Head = styled(Grid)`
  align-items: end;
  position: relative;
  z-index: 1;
`

const Plan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 33px 15px;
  background: hsl(209deg, 88%, 8.5%);
`

const Name = styled.h3`
  font-size: 1.6rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin: 0 0 8px;
  font-weight: ${p => p.theme.weights.medium};
  line-height: 1.5;
`

const Price = styled.p`
  margin: 0 0 16px;
  position: relative;
`

const PAmount: React.FC = ({ children, ...props }) => (
  <Heading variant="lg" as="span" {...props}>
    {children}
  </Heading>
)

const PTime = styled.span`
  font-size: 2.4rem;
  font-weight: ${p => p.theme.weights.bold};
`

const PSaving = styled.span`
  position: absolute;
  top: -6px;
  right: -37px;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${p => p.theme.colors.product.hub.accent};
  font-weight: ${p => p.theme.weights.bold};
`

const Tagline = styled.p`
  font-size: 1.35rem;
  margin: 0;
`

const GetStartedButton: React.FC = ({ children, ...props }) => (
  <Button
    elementType="link"
    uppercase
    mt={`27px`}
    bg={theme.colors.text.inverted.base}
    color={theme.colors.product.hub.bg}
    hover={{
      bg: theme.colors.text.inverted.dark,
    }}
    {...props}
  >
    {children}
  </Button>
)

const GetStartedGreenButton: React.FC = ({ children, ...props }) => (
  <Button
    elementType="link"
    uppercase
    mt={`27px`}
    bg={theme.colors.product.hub.accent}
    color={theme.colors.product.hub.bg}
    hover={{
      bg: theme.colors.product.hub.dark,
    }}
    {...props}
  >
    {children}
  </Button>
)

const BillingToggle = styled(Flex)`
  font-size: 1.4rem;

  > span {
    font-size: 1.35rem;
  }
`

const Row = styled.div`
  border-radius: 8px;
  background-image: linear-gradient(90deg, hsl(214deg 11% 41%), #07192d);
  padding: 1px;
  margin: 15px 0 0;
`

const RowInner = styled.div`
  background-image: linear-gradient(90deg, hsl(214deg 36% 20%), #07192d);
  border-radius: 8px;
`

const RowTitle = styled.p`
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: ${p => p.theme.colors.product.hub.accent};
  font-weight: ${p => p.theme.weights.medium};
  padding: 8px 12px 15px;
  margin: 0;
  line-height: 24px;
`

const Cells = styled(Grid)`
  padding: 0 0 23px;

  &:last-child {
    padding: 0 0 12px;
  }
`

const TitleCell = styled.div`
  justify-self: start;
  padding: 0 12px;
  margin: 0;
  font-weight: ${p => p.theme.weights.medium};
  font-size: 1.6rem;
  display: flex;
  line-height: 1.5;

  &:before {
    content: "\\2022";
    margin: 0 6px 0 0;
    font-size: 2rem;
    line-height: 1.1;
  }
`

const Cell = styled.div`
  justify-self: center;
  padding: 0 4px;
  margin: 0;
  font-size: 1.6rem;
`

/* Gridlines – vertical column lines */
const Gridlines = styled(Grid)<{
  height: string
}>`
  position: absolute;
  top: 0;
  width: 100%;
  height: ${props => `${props.height}px` || "100%"};
  max-height: 100%;
  padding: 50px 0 0;

  > div {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
`

const Gridline = styled.div<{
  premium?: boolean
  blur?: boolean
}>`
  width: 1px;
  height: 100%;

  ${p =>
    p.premium
      ? css`
          background: linear-gradient(
            180deg,
            hsl(68deg, 79%, 60%),
            hsl(212deg, 73%, 10%, 0%) 93%
          );
        `
      : css`
          background-image: linear-gradient(
            180deg,
            hsl(214deg, 11%, 41%),
            hsl(212deg, 73%, 10%, 0%)
          );
        `}

  ${p =>
    p.blur &&
    css`
      width: 2px;
      filter: blur(12px);
    `}
`
