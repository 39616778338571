import React from "react"
import { Button } from "../../elements"
import theme from "../../styles/theme"

const ButtonPrimary: React.FC = ({ children, ...props }) => (
  <Button
    uppercase
    bg={theme.colors.product.hub.accent}
    color={theme.colors.product.hub.bg}
    hover={{
      bg: theme.colors.product.hub.dark,
    }}
    large
    {...props}
  >
    {children}
  </Button>
)

const ButtonSecondary: React.FC = ({ children, ...props }) => (
  <Button
    uppercase
    hollow
    large
    borderColor={theme.colors.product.hub.accent}
    color={theme.colors.product.hub.accent}
    hover={{
      bg: theme.colors.product.hub.dark,
      color: theme.colors.product.hub.bg,
    }}
    {...props}
  >
    {children}
  </Button>
)

export { ButtonPrimary, ButtonSecondary }
