import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "./EventCard.scss"
import getRedirectUrl from "../../utils/getRedirectUrl"

interface Props {
  post: {
    title: string
    feature_image: string
    primary_tag: {
      slug: string
    }
    slug: string
    html: string
    tags: Array<Tags>
    codeinjection_head: string
  }
}

interface Tags {
  name: string
  slug: string
}

const EventCard = ({ post }: Props) => {
  const featuredImage = getImage(post.featureImageSharp)
  const external_link = getRedirectUrl(post?.codeinjection_head)

  return (
    <div
      className={
        "event-card event-card--" +
        (post?.tags[1]?.slug ? post?.tags[1]?.slug : "default") +
        " post-feed__card " +
        (featuredImage ? "" : " no-image")
      }
    >
      <a
        href={external_link ? external_link : `/events/${post.slug}/`}
        className="event-card__wrapper"
      >
        {/* Featured Image */}
        <div className="event-card__image">
          {featuredImage && (
            <GatsbyImage
              image={featuredImage}
              alt={post.title}
              imgStyle={{
                borderRadius: "8px",
              }}
            />
          )}
        </div>

        {/* Card Content */}
        <div className="event-card__content">
          {/* Tag */}
          <div className="event-card__tag">{post.tags[1]?.name}</div>

          {/* Title */}
          <h2 className="event-card__title">{post.title}</h2>

          {/* Event summary */}
          <div
            className="event-card__summary"
            dangerouslySetInnerHTML={{ __html: post.html }}
          ></div>

          {/* Link */}
          <div className="link--with-arrow">Join Event</div>
        </div>
      </a>
    </div>
  )
}

export default EventCard
