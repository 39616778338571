import React from "react"
import styled from "styled-components"

import { Box, Flex, Paragraph } from "../../elements"

const OnboardingStep = ({ number, children, first, last }) => (
  <Wrapper first={first} last={last}>
    <Flex
      width={`100%`}
      height={`48px`}
      maxWidth={`48px`}
      alignItems={`center`}
      justifyContent={`center`}
      border={`solid 2px #f9fafa`}
      borderRadius={`100%`}
      backgroundColor={`#d5ea48`}
      position="relative"
      zIndex={1}
    >
      <Paragraph variant="lg" fontWeight="bold" m={0} p={0}>
        {number}
      </Paragraph>
    </Flex>
    <Box mt={[`16px`]} ml={[`16px`, null, 0]}>
      <Paragraph
        as="p"
        fontWeight={"bold"}
        fontSize={["1.6rem"]}
        m={0}
        p={0}
        textAlign={["left", null, "center"]}
        color="text.inverted.base"
      >
        {children}
      </Paragraph>
    </Box>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 135px;

  &::after {
    content: "";
    position: absolute;
    left: 23px;
    width: 2px;
    height: 100%;
    background-color: #f9fafa;
  }

  ${({ last }) =>
    last &&
    `
        height: auto;
        &::after {
          content: none;
        }
      `}

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    flex-direction: column;
    align-items: center;
    max-width: 184px;
    height: auto;

    &::after {
      position: absolute;
      top: 23px;
      left: auto;
      height: 2px;
      width: 100%;
    }

    ${({ first }) =>
      first &&
      `
      &::after {
        width: 50%;
        right: 0;
      }
  `}

    ${({ last }) =>
      last &&
      `
        height: auto;
        
        &::after {
          content: "";
          width: 50%;
          left: 0;
        }
      `}
  }
`

const OnboardingStepLine = () => (
  <Flex
    height="48px"
    alignItems="center"
    width="100%"
    display={["none", null, "flex"]}
  >
    <Box width="100%" height="2px" backgroundColor="#f9fafa"></Box>
  </Flex>
)

export { OnboardingStep, OnboardingStepLine }
