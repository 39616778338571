import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { BoxedContainer } from "../elements"
import theme from "../styles/theme"

interface Props {
  title: string
  logo?: string
  backgroundImage?: object
}

const PageHeader = ({ title, logo, backgroundImage }: Props) => (
  <Wrapper>
    <BoxedContainer
      style={{
        position: "relative",
        zIndex: 1,
      }}
    >
      {logo && <Logo src={logo} alt="" />}
      <Title>{title}</Title>
    </BoxedContainer>
    {backgroundImage && (
      <GatsbyImage
        image={getImage(backgroundImage)}
        style={{
          position: "absolute",
          top: "0",
          width: "100%",
          height: "100%",
          zIndex: 0,
        }}
      />
    )}
  </Wrapper>
)

export default PageHeader

const Wrapper = styled.section`
  padding: 80px 0;
  background-color: ${theme.colors.background.dark};
  color: #fff;
  text-align: center;
  position: relative;
`

const Logo = styled.img`
  width: 100%;
  max-width: 190px;
  margin: 0 0 25px;
`

const Title = styled.h1`
  margin: 0;
`
