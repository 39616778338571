import React from "react"
import styled from "styled-components"

const ToggleTrack: React.FC<{
  bg: string
  color: string
  active: boolean
  onClick: () => void
  style: any
}> = ({ bg, color, active, ...props }) => {
  return (
    <Track type="button" bg={bg} {...props}>
      <Thumb color={color} active={active}></Thumb>
    </Track>
  )
}

export default ToggleTrack

type TrackProps = {
  bg: string
}

const Track = styled.button.attrs<TrackProps>(props => ({
  bg: props.bg || props.theme.colors.primary.base,
}))<TrackProps>`
  background-color: ${props => props.bg};
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  height: 24px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 45px;
  border: none;
  padding: 2px;
  align-items: center;
  justify-content: space-between;
`

type ThumbProps = {
  color: string
  active: boolean
}

const Thumb = styled.span.attrs<ThumbProps>(props => ({
  color: props.color || `#fff`,
  active: props.active || false,
}))<ThumbProps>`
  background: ${props => props.color};
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  left: 2px;
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform: ${props => (props.active ? `translateX(21px)` : `translateX(0)`)};
`
