import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import {
  CustomBulletList,
  CustomBulletListItem as ListItem,
} from "../../elements/Lists"

import { Layout, MetaData } from "../../components"
import {
  BoxedContainer,
  Box,
  Flex,
  Grid,
  Image,
  Heading,
  Paragraph,
  StyledLink,
} from "../../elements"
import { padding } from "../../styles/theme"
import {
  Merit,
  Feature,
  OnboardingStep,
  OnboardingStepLine,
  ButtonPrimary,
  ButtonSecondary,
} from "../../components/hub"
import ResourceCard from "../../components/ResourceCard"

import hubLogo from "../../images/hub/traefik-hub-beta-logo.png"
import listCheckIcon from "../../images/hub-beta/specs/icons-system-check.svg"
import hubIcon from "../../images/hub-beta/cta/logos-traefik-hub-icon.svg"
import diagramBg from "../../images/hub-beta/diagram/diagram-bg.svg"
import featuresCertificate from "../../images/hub-beta/features/icons-duotone-dark-certificate.svg"
import featuresMetrics from "../../images/hub-beta/features/icons-duotone-dark-metrics-dashboard.svg"
import featuresClick from "../../images/hub-beta/features/icons-duotone-dark-one-click.svg"
import featuresSecurity from "../../images/hub-beta/features/icons-duotone-dark-security.svg"
import featuresAccess from "../../images/hub-beta/features/icons-duotone-dark-access-control.svg"
import stepsBg from "../../images/hub-beta/steps/bg.svg"

const TraefikHubPage = ({ data, location }) => {
  const resources = data.resources.edges

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.currentPage,
        }}
      />

      {/* Hero */}
      <Box bg="product.hub.bg" color="text.inverted.base" position="relative">
        <Box position="absolute" top="0" right="0" width="100%" height="100%">
          <StaticImage
            src="../../images/hub-beta/hero/bg@3x.png"
            alt=""
            layout="fullWidth"
            objectFit="cover"
            objectPosition="top center"
            style={{
              height: "100%",
            }}
            imgStyle={{
              height: "100%",
            }}
            formats={["auto", "webp", "avif"]}
            placeholder="none"
            quality={75}
          />
          <Box
            display={["none", null, null, null, null, null, "block"]}
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            height="70px"
            backgroundImage="linear-gradient(0deg, #021424, transparent)"
          ></Box>
        </Box>
        <BoxedContainer position="relative" zIndex={1}>
          <Flex
            flexDirection="column"
            alignItems="center"
            p={["80px 0 30px"]}
            textAlign="center"
          >
            <Image
              src={hubLogo}
              height={["50px", "56px"]}
              mb={"28px"}
              alt="Traefik Hub logo"
            />
            <Heading variant="lg" as="h1">
              Your Cloud Native Networking Platform
            </Heading>
            <Paragraph variant="lg" color="#ccd1d5">
              Publishing and securing your containers has never been easier.
            </Paragraph>
            <Flex>
              <ButtonPrimary
                elementType="link"
                href="https://hub.traefik.io/"
                mt={`27px`}
              >
                Get Started
              </ButtonPrimary>
              <ButtonSecondary
                elementType="link"
                href="https://info.traefik.io/meet-traefik-hub"
                ml={`16px`}
                mt={`27px`}
              >
                Take a Tour
              </ButtonSecondary>
            </Flex>
            <StaticImage
              src="../../images/hub-beta/hero/traefik-hub-dashboard.png"
              alt=""
              width={1224}
              loading="eager"
              formats={["auto", "webp", "avif"]}
              placeholder="none"
              quality={75}
            ></StaticImage>
          </Flex>
        </BoxedContainer>
      </Box>

      {/* Intro */}
      <Box as="section" bg="product.hub.bg2x" pt={padding.yaxis.medium}>
        <BoxedContainer variant="narrow">
          <Box maxWidth="810px" mx="auto" textAlign="center">
            <Heading as="h2" variant="md" color="text.inverted.base">
              What is Traefik Hub?
            </Heading>
            <Paragraph variant="md" mt="24px" color="product.hub.textLight">
              Traefik Hub is a cloud native networking platform that helps
              publish and secure containers at the edge instantly. Traefik Hub
              provides a gateway to your services running on Kubernetes or other
              orchestrators.
            </Paragraph>
          </Box>
        </BoxedContainer>
        <BoxedContainer>
          <Grid
            gridTemplateColumns={["1fr", null, "repeat(3, 1fr)"]}
            mt={["60px", null, null, "113px"]}
            gridGap={["56px", null, "24px"]}
            justifyItems="center"
          >
            <Merit
              icon={data.iconPublish}
              title="Publish"
              description="Deploy Hub container, select your services, and get secured public access to your containers in seconds."
            />
            <Merit
              icon={data.iconSecure}
              title="Secure"
              description="Access your containers through secured tunnels, deploy industry-standard authentication, and automate TLS certificate management."
            />
            <Merit
              icon={data.iconScale}
              title="Scale"
              description="Start with a single Kubernetes or Docker cluster and seamlessly scale to multiple clusters within your centralized Hub dashboard."
            />
          </Grid>
        </BoxedContainer>
        <Box mt="24px">
          <BoxedContainer>
            <StaticImage
              src="../../images/hub-beta/intro/bg-light.png"
              placeholder="none"
              alt=""
              layout="fullWidth"
              objectFit="contain"
              objectPosition="bottom center"
              formats={["auto", "webp", "avif"]}
            />
          </BoxedContainer>
        </Box>
      </Box>

      {/* Diagram */}
      <Box as="section" bg="product.hub.bg2x" color="text.inverted.base">
        <BoxedContainer position="relative">
          <Flex
            flexDirection="column"
            alignItems="center"
            py={padding.yaxis.default}
            position="relative"
          >
            <Box
              style={{
                backgroundImage: `url(${diagramBg})`,
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
                backgroundPosition: "top center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></Box>
            <Flex
              flexDirection="column"
              alignItems="center"
              position="relative"
              zIndex={1}
            >
              <Heading as="h2" textAlign="center" variant="md">
                How Does Traefik Hub Work?
              </Heading>
              <Flex
                justifyContent="center"
                position="relative"
                mt={"40px"}
                width="100%"
              >
                <StaticImage
                  src="../../images/hub-beta/diagram/diagram.png"
                  alt="Traefik Hub diagram"
                  width={1130}
                  placeholder="none"
                />
              </Flex>
            </Flex>
          </Flex>
        </BoxedContainer>
      </Box>

      {/* Key Features */}
      <Box
        as="section"
        bg="product.hub.bg2x"
        pt={padding.yaxis.medium}
        pb={padding.yaxis.large}
      >
        <BoxedContainer>
          <Heading
            as="h2"
            variant="uppercase"
            textAlign="center"
            mb={["60px", null, null, "80px"]}
            color="product.hub.accent"
          >
            Key Features
          </Heading>
          <Grid gridGap={["60px", null, null, "144px"]}>
            <Feature
              title={
                <>
                  <Heading
                    as="span"
                    variant="default"
                    color="product.hub.accent"
                  >
                    One-Click
                  </Heading>{" "}
                  Service Publication
                </>
              }
              description="Being accessible anywhere at the edge has never been easier. For each published service, Traefik Hub provides a unique DNS Name that can be used immediately to access the container from anywhere on the Internet."
              icon={<img src={featuresClick} alt="" />}
              image={data.featuresPublish}
            />

            <Feature
              title={
                <>
                  <Heading
                    as="span"
                    variant="default"
                    color="product.hub.accent"
                  >
                    Secure
                  </Heading>{" "}
                  Tunnels
                </>
              }
              description="Traefik Hub connects to your clusters over a private, encrypted tunnel. Utilizing the tunnel allows you to publish your containers for external access, without worrying about complex and insecure public IP or NAT configurations."
              icon={<img src={featuresSecurity} alt="" />}
              image={data.featuresSecurity}
              reverse
            />

            <Feature
              title={
                <>
                  <Heading
                    as="span"
                    variant="default"
                    color="product.hub.accent"
                  >
                    Automated
                  </Heading>{" "}
                  Certificate Management
                </>
              }
              description="Traefik Hub will request, renew, and propagate ACME certificates to all of your clusters so that all services maintain a consistent security configuration."
              icon={<img src={featuresCertificate} alt="" />}
              image={data.featuresACME}
            />

            <Feature
              title={
                <>
                  <Heading
                    as="span"
                    variant="default"
                    color="product.hub.accent"
                  >
                    Flexible
                  </Heading>{" "}
                  Access Control
                </>
              }
              description="Protect and secure access to your services, regardless of which orchestrators you use. Traefik Hub supports  JSON Web Tokens (JWT), Basic Auth."
              icon={<img src={featuresAccess} alt="" />}
              image={data.featuresAccessControl}
              reverse
            />
            <Feature
              title={
                <>
                  <Heading
                    as="span"
                    variant="default"
                    color="product.hub.accent"
                  >
                    Centralized
                  </Heading>{" "}
                  Multi-Cluster Dashboard
                </>
              }
              description="Easily visualize all information about all your clusters, their configuration, and inbound traffic for service performance and health within a single pane of glass."
              icon={<img src={featuresMetrics} alt="" />}
              image={data.featuresCentralized}
            />
          </Grid>
        </BoxedContainer>
      </Box>

      <Box position="relative">
        <BoxedContainer position="relative" zIndex={1}>
          <Flex
            borderRadius={"16px"}
            padding={["60px 20px", "80px 25px"]}
            flexDirection="column"
            alignItems={"center"}
            style={{
              backgroundImage: `url(${stepsBg})`,
              backgroundPosition: "top center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Heading
              as="h2"
              variant="md"
              textAlign={"center"}
              color="text.inverted.base"
            >
              Get started in minutes
            </Heading>
            <Flex
              mt={`40px`}
              maxWidth={"858px"}
              width={"100%"}
              flexDirection={["column", null, "row"]}
            >
              <OnboardingStep number={1} first>
                Create an account
              </OnboardingStep>
              <OnboardingStepLine />
              <OnboardingStep number={2}>
                Install the Traefik Hub agent
              </OnboardingStep>
              <OnboardingStepLine />
              <OnboardingStep number={3} last>
                Publish your services instantly
              </OnboardingStep>
            </Flex>
            <Flex mt={"40px"}>
              <ButtonPrimary elementType="link" href="http://hub.traefik.io/">
                Create Your Account
              </ButtonPrimary>
            </Flex>
          </Flex>
        </BoxedContainer>
        <Box position="absolute" top={0} left={0} width="100%" height="100%">
          <Box bg="product.hub.bg2x" height="50%"></Box>
          <Box bg="product.hub.bg2x" height="50%"></Box>
        </Box>
      </Box>

      {/* Technical Specifications */}
      <Box as="section" bg="product.hub.bg2x" py={padding.yaxis.large}>
        <BoxedContainer>
          <Grid
            gridTemplateColumns={["1fr", null, "repeat(3, 1fr)"]}
            gridGap={["40px"]}
          >
            <Box>
              <Heading as="h2" variant="md" color="text.inverted.base">
                Technical Specifications
              </Heading>
              {/* <Paragraph
                color="product.hub.textLight"
                fontSize={["1.4rem"]}
                as="p"
                mt="16px"
              >
                For more technical details, visit the{" "}
                <StyledLink
                  href="https://doc.traefik.io/"
                  color="product.hub.dark"
                  fontSize="1.4rem"
                  style={{ textDecoration: "underline" }}
                  hover={{
                    color: "hsl(68deg, 79%, 60%)",
                  }}
                >
                  Traefik Hub documentation
                </StyledLink>
                .
              </Paragraph> */}
            </Box>
            <Box>
              <Heading as="h3" variant="sm" color="text.inverted.base">
                Supported Orchestrator Platforms
              </Heading>
              <List mt="24px" color="product.hub.textLight">
                <ListItem>Any Kubernetes distribution</ListItem>
                <ListItem>Docker</ListItem>
                <ListItem>Docker Swarm</ListItem>
              </List>
            </Box>
            <Flex flexDirection="column">
              <Box>
                <Heading as="h3" variant="sm" color="text.inverted.base">
                  Supported Ingress Controllers
                </Heading>
                <List mt="24px" mb={0} color="product.hub.textLight">
                  <ListItem>Traefik Proxy version 2.8</ListItem>
                </List>
              </Box>
              <Box mt="40px">
                <Heading as="h3" variant="sm" color="text.inverted.base">
                  Internal technology used
                </Heading>
                <List mt="24px" mb={0} color="product.hub.textLight">
                  <ListItem>Let’s Encrypt</ListItem>
                  <ListItem>Connection multiplexing</ListItem>
                  <ListItem>Secured tunneling</ListItem>
                </List>
              </Box>
            </Flex>
          </Grid>
        </BoxedContainer>
      </Box>

      {/* CTA */}
      <Box as="section" bg="product.hub.bg2x" position="relative">
        <Box position="absolute" top="0" right="0" width="100%" height="100%">
          <StaticImage
            src="../../images/hub-beta/cta/bg@3x.png"
            alt=""
            layout="fullWidth"
            objectFit="cover"
            objectPosition="bottom center"
            style={{
              height: "100%",
            }}
            imgStyle={{
              height: "100%",
            }}
            formats={["auto", "webp", "avif"]}
            placeholder="none"
          />
          <Box
            display={["none", null, null, null, null, null, "block"]}
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="70px"
            backgroundImage="linear-gradient(180deg, #021424, transparent)"
          ></Box>
        </Box>
        <BoxedContainer position="relative">
          <Flex
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            pt={padding.yaxis.default}
            pb={padding.yaxis.large}
          >
            <img src={hubIcon} alt="Traefik Hub Icon" />
            <Heading
              as="div"
              variant="uppercase"
              color="product.hub.accent"
              mt="16px"
            >
              Traefik Hub
            </Heading>
            <Heading as="h2" variant="md" color="product.hub.bgLight" mt="16px">
              Simplicity is in our DNA
            </Heading>
            <Paragraph
              as="p"
              variant="sm"
              color="product.hub.textLight"
              mt="24px"
            >
              Get started today with Traefik Hub
            </Paragraph>
            <ButtonPrimary
              elementType="link"
              href="https://hub.traefik.io/"
              mt={`24px`}
            >
              Create Your Account
            </ButtonPrimary>
          </Flex>
        </BoxedContainer>
      </Box>

      {/* Resources */}
      {!!resources.length && (
        <Box as="section" bg="product.hub.bgLight" py={padding.yaxis.large}>
          <BoxedContainer>
            <Heading as="h2" variant="md" textAlign="center">
              Learn More about Traefik Hub
            </Heading>
            <Grid
              gridTemplateColumns={[
                "1fr",
                "repeat(2, 1fr)",
                null,
                "repeat(3, 1fr)",
              ]}
              gridGap={["60px", "40px 30px", null, "60px 30px"]}
              justifyItems="center"
              mt={["60px", null, null, "80px"]}
            >
              {resources.map(({ node }) => (
                <ResourceCard
                  key={node.id}
                  data={node}
                  styles={{
                    linkColor: "product.hub.dark2x",
                    eyebrowColor: "text.light",
                  }}
                />
              ))}
            </Grid>
          </BoxedContainer>
        </Box>
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    currentPage: ghostPage(slug: { eq: "traefik-hub" }) {
      ...PageMetaFields
    }

    resources: allGhostPost(
      filter: {
        tags: {
          elemMatch: { slug: { eq: "hash-traefik-hub-related-resources" } }
        }
      }
      limit: 3
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }

    iconPublish: file(
      relativePath: { eq: "hub-beta/intro/icons-features-publish.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 113
          height: 114
          placeholder: NONE
          quality: 100
        )
      }
    }

    iconSecure: file(
      relativePath: { eq: "hub-beta/intro/icons-features-secure.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 113
          height: 114
          placeholder: NONE
          quality: 100
        )
      }
    }

    iconScale: file(
      relativePath: { eq: "hub-beta/intro/icons-features-scale.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 113
          height: 114
          placeholder: NONE
          quality: 100
        )
      }
    }

    featuresPublish: file(
      relativePath: {
        eq: "hub-beta/features/key-features-graphics-publish-service@3x.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, placeholder: NONE, quality: 80)
      }
    }

    featuresSecurity: file(
      relativePath: {
        eq: "hub-beta/features/key-features-graphics-secure-tunnels@3x.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, placeholder: NONE, quality: 80)
      }
    }
    featuresACME: file(
      relativePath: {
        eq: "hub-beta/features/key-features-graphics-certificate-management@3x.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, placeholder: NONE, quality: 80)
      }
    }
    featuresAccessControl: file(
      relativePath: {
        eq: "hub-beta/features/key-features-graphics-access-control@3x.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, placeholder: NONE, quality: 80)
      }
    }
    featuresCentralized: file(
      relativePath: {
        eq: "hub-beta/features/key-features-graphics-dashboard@3x.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, placeholder: NONE, quality: 80)
      }
    }
  }
`

const List = styled(CustomBulletList).attrs(props => ({
  icon: listCheckIcon,
  iconSize: `27px`,
}))`
  li {
    line-height: 1.5;
    margin: 0 0 16px;

    &:last-child {
      margin: 0;
    }

    &::before {
      top: -2px;
    }
  }
`

export default TraefikHubPage
